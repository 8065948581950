import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClienteScorePending,
  updateClienteScore,
} from "../../slices/dashboardSlice";
import NavegacaoPending from "../../components/NavegacaoPending";
import { FaUserCircle, FaComment } from "react-icons/fa";
import { FcFilledFilter } from "react-icons/fc";
import { useCNPJ } from "../../components/CNPJContext";
import { useNavigate } from "react-router-dom";

const Pending = () => {
  const { setCnpj } = useCNPJ(); // Use o hook para acessar o setter do CNPJ
  const navigate = useNavigate(); // Inicialize o hook para navegação

  const handleCounterpartyClick = (cnpj) => {
    setCnpj(cnpj); // Atualize o CNPJ no contexto
    navigate("/pessoa-juridica/score"); // Redirecione para a rota desejada
  };

  const dispatch = useDispatch();
  const { clienteScoresPendings } = useSelector((state) => state.dashboard);

  // Estados para controlar o popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [opinion2, setOpinion2] = useState("");
  const [observations, setObservations] = useState("");
  const [limitValue, setLimitValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [anoMes, setAnoMes] = useState("");
  const [ano, setAno] = useState("");
  const [mes, setMes] = useState("");
  const [parecer1, setParecer1] = useState("Pending");
  const [parecer2, setParecer2] = useState("N/A"); // Inicia como "N/A"

  useEffect(() => {
    // Obtém o ano e o mês atual
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0"); // .getMonth() retorna de 0 a 11, por isso somamos 1

    setAno(currentYear.toString());
    setMes(currentMonth);
  }, []);

  // Quando o componente for atualizado
  useEffect(() => {
    const opinion2ToSend = parecer2 === "N/A" ? null : parecer2; // Converte "N/A" para null

    dispatch(
      getClienteScorePending({
        anoMes,
        opinion1: parecer1,
        opinion2: opinion2ToSend,
      })
    );
  }, [dispatch, anoMes, parecer1, parecer2]);

  // Formata o CNPJ
  const formatCNPJ = (cnpj) => {
    if (!cnpj) return "";
    const cnpjNumbers = cnpj.replace(/\D/g, "");
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  // Função para converter 'yyyy-mm-dd' para 'dd/mm/yyyy'
  const formatDateToDisplay = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const openPopup = (score) => {
    setSelectedScore(score);
    setOpinion2(score.opinion2 || ""); // Preenche com a opinião atual, se houver
    setObservations(score.observations || ""); // Preenche com as observações atuais, se houver
    setLimitValue(score.limitValue || "");
    setExpiryDate(formatDateToDisplay(score.expiryDate || ""));
    setIsPopupOpen(true);
  };

  // Fechar o popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedScore(null);
  };

  // Função para converter 'dd/mm/yyyy' para 'yyyy-mm-dd'
  const formatDateToSubmit = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  // Submeter a atualização de opinion2, observations, limitValue e expiryDate

  const handleSubmit = () => {
    if (selectedScore) {
      dispatch(
        updateClienteScore({
          counterpartyCnpj: selectedScore.counterpartyCnpj,
          opinion2,
          observations,
          limitValue,
          expiryDate: formatDateToSubmit(expiryDate), // Converte antes de enviar
        })
      );
    }
    closePopup(); // Fecha o popup após a submissão
  };

  function translateOpinion(opinion) {
    switch (opinion) {
      case "Approved":
        return "Aprovado";
      case "Rejected":
        return "Rejeitado";
      case "Pending":
        return "Pendente";
      default:
        return "N/A";
    }
  }

  // Gera os anos disponíveis (ajuste conforme necessário)
  const anos = Array.from({ length: 20 }, (_, i) =>
    (new Date().getFullYear() - 10 + i).toString()
  );

  // Opções de parecer
  const parecerOptions = [
    { value: "Todos", label: "Todos" },
    { value: "Pending", label: "Pendente" },
    { value: "Rejected", label: "Rejeitado" },
    { value: "Approved", label: "Aprovado" },
  ];

  const parecerOptions2 = [
    { value: "Rejected", label: "Rejeitado" },
    { value: "Approved", label: "Aprovado" },
  ];

  // Atualiza anoMes quando ano ou mes mudam
  useEffect(() => {
    if (ano && mes) {
      setAnoMes(`${ano}-${mes}`);
    }
  }, [ano, mes, setAnoMes]);

  return (
    <div className="p-4 pt-0">
      <NavegacaoPending />
      <h2 className="text-3xl font-bold mb-4 text-white">Solicitações</h2>
      <div className="flex flex-col items-start space-y-2 p-2 bg-white rounded-lg shadow-md mb-4">
        <div className="flex items-center">
          <FcFilledFilter className="h-10 w-10" />
          <h2 className="text-xl font-bold tracking-wider underline">
            Filtros
          </h2>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col space-y-2 w-40">
            <label htmlFor="ano" className="font-semibold text-gray-700">
              Ano:
            </label>
            <select
              id="ano"
              value={ano}
              onChange={(e) => {
                const value = e.target.value;

                setAno(value);
              }}
              className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {anos.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col space-y-2 w-40">
            <label htmlFor="mes" className="font-semibold text-gray-700">
              Mês:
            </label>
            <select
              id="mes"
              value={mes}
              onChange={(e) => {
                const value = e.target.value;

                setMes(value); // Continua a definir o número do mês
              }}
              className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {[
                { name: "Janeiro", value: "01" },
                { name: "Fevereiro", value: "02" },
                { name: "Março", value: "03" },
                { name: "Abril", value: "04" },
                { name: "Maio", value: "05" },
                { name: "Junho", value: "06" },
                { name: "Julho", value: "07" },
                { name: "Agosto", value: "08" },
                { name: "Setembro", value: "09" },
                { name: "Outubro", value: "10" },
                { name: "Novembro", value: "11" },
                { name: "Dezembro", value: "12" },
              ].map((m) => (
                <option key={m.value} value={m.value}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>

          {/* Select de Parecer 1 */}
          <div className="flex flex-col space-y-2 w-40">
            <label htmlFor="parecer1" className="font-semibold text-gray-700">
              Parecer 1:
            </label>
            <select
              id="parecer1"
              value={parecer1}
              onChange={(e) => setParecer1(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {parecerOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Select de Parecer 2 */}
          <div className="flex flex-col space-y-2 w-40">
            <label htmlFor="parecer2" className="font-semibold text-gray-700">
              Parecer 2:
            </label>
            <select
              id="parecer2"
              value={parecer2}
              onChange={(e) => setParecer2(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Todos">Todos</option>
              <option value="N/A">N/A</option>
              {parecerOptions2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">
                Data Processo
              </th>
              <th className="py-2 px-4 border-b text-left">Contraparte</th>
              <th className="py-2 px-4 border-b text-left">Score</th>
              <th className="py-2 px-4 border-b text-left">CNPJ</th>
              <th className="py-2 px-4 border-b text-center">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaUserCircle size={30} />
                  <FaComment size={20} style={{ marginLeft: 5 }} />
                </div>
              </th>
              <th className="py-2 px-4 border-b text-left">
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaUserCircle size={30} />
                  <FaComment size={20} style={{ marginLeft: 5 }} />
                  <div
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -10,
                      color: "black",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                    }}
                  >
                    2
                  </div>
                </div>
              </th>
              <th className="py-2 px-4 border-b text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            {clienteScoresPendings && clienteScoresPendings.length > 0 ? (
              clienteScoresPendings.map((score, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{score.processDate || "N/A"}</td>
                  <td
                    className="py-2 px-4 text-gray-500 hover:text-blue-500 cursor-pointer"
                    onClick={() =>
                      handleCounterpartyClick(score.counterpartyCnpj)
                    }
                  >
                    {score.counterpartyName}
                  </td>
                  <td className="py-2 px-4">{score.score}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    {formatCNPJ(score.counterpartyCnpj)}
                  </td>
                  <td className="py-2 px-4">
                    {translateOpinion(score.opinion1)}
                  </td>
                  <td className="py-2 px-4">
                    {translateOpinion(score.opinion2)}
                  </td>
                  <td className="py-2 px-4">
                    <button
                      className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={() => openPopup(score)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="py-4 text-center text-gray-500">
                  Nenhum cliente pendente encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-bold mb-4">Atualizar Contraparte</h3>

            {selectedScore && (
              <div className="mb-4">
                <p>{selectedScore.counterpartyName}</p>
              </div>
            )}

            {/* Seletor de Opinion 2 */}
            <div className="mb-4">
              <label className="block mb-2">Parecer 2</label>
              <select
                className="border border-gray-300 p-2 rounded w-full"
                value={opinion2}
                onChange={(e) => setOpinion2(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Approved">Aprovado</option>
                <option value="Rejected">Rejeitado</option>
              </select>
            </div>

            {/* Campo de Observações */}
            <div className="mb-4">
              <label className="block mb-2">Observações</label>
              <textarea
                className="border border-gray-300 p-2 rounded w-full"
                rows="4"
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
            </div>

            {/* Campo de LimitValue */}
            <div className="mb-4">
              <label className="block mb-2">Limite</label>
              <input
                className="border border-gray-300 p-2 rounded w-full"
                type="text"
                value={limitValue}
                onChange={(e) => setLimitValue(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Data de Expiração</label>
              <input
                className="border border-gray-300 p-2 rounded w-full"
                type="text"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </div>

            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600"
                onClick={closePopup}
              >
                Cancelar
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                onClick={handleSubmit}
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pending;
