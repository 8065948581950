import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "../services/dashboardService"; // Importe o serviço atualizado

const initialState = {
  dashboards: [],
  dashboards2: [],
  clienteScores: [],
  clienteScoresPendings: [],
  dashboard: {},
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDashboard = createAsyncThunk(
  "dashboard/getdashboard",
  async ({ data1, data2, operationType }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dashboardService.getDashboard(data1, data2, operationType);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDashboard2 = createAsyncThunk(
  "dashboard/getdashboard2",
  async ({ data1, score }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");
    console.log("data:", data1);

    const data = await dashboardService.getDashboard2(data1, score);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getClienteScore = createAsyncThunk(
  "dashboard/getclientescore",
  async ({ ano_mes }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dashboardService.getClienteScore(ano_mes);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getClienteScorePending = createAsyncThunk(
  "dashboard/getclientescorepending",
  async ({ anoMes, opinion1, opinion2 }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dashboardService.getClienteScorePending(anoMes, opinion1, opinion2);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const updateClienteScore = createAsyncThunk(
  "dashboard/updateClienteScore",
  async ({ counterpartyCnpj, opinion2, observations, limitValue, expiryDate }, thunkAPI) => {
    console.log("Atualizando cliente score com os seguintes dados:", {
      counterpartyCnpj,
      opinion2,
      observations,
      limitValue,
      expiryDate
    });

    try {
      const response = await dashboardService.updateClienteScore(
        counterpartyCnpj,
        opinion2,
        observations,
        limitValue,
        expiryDate
      );
      return response; // A resposta será retornada como o payload da action
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dashboards = action.payload;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDashboard2.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDashboard2.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dashboards2 = action.payload;
      })
      .addCase(getDashboard2.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getClienteScore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getClienteScore.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.clienteScores = action.payload;
      })
      .addCase(getClienteScore.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getClienteScorePending.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getClienteScorePending.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.clienteScoresPendings = action.payload;
      })
      .addCase(getClienteScorePending.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(updateClienteScore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateClienteScore.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
  
        // Aqui você pode atualizar localmente o clienteScore na lista, se necessário
        const updatedIndex = state.clienteScores.findIndex(
          (score) => score.counterpartyCnpj === action.meta.arg.counterpartyCnpj
        );
  
        if (updatedIndex !== -1) {
          state.clienteScores[updatedIndex].opinion2 = action.meta.arg.opinion2;
          state.clienteScores[updatedIndex].observations = action.meta.arg.observations;
          state.clienteScores[updatedIndex].limitValue = action.meta.arg.limitValue;
          state.clienteScores[updatedIndex].expiryDate = action.meta.arg.expiryDate;
        }
      })
      .addCase(updateClienteScore.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { resetMessage } = dashboardSlice.actions;
export default dashboardSlice.reducer;
