import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getProcessosJudAdm } from "../slices/processosPFSlice";
// Importe a ação e o selector aqui quando estiverem disponíveis

function ProcessosJA({ cpf, search }) {
  const dispatch = useDispatch();
  const { processosJudAdmPF } = useSelector((state) => state.processosPF); // Substitua pelo seu selector quando disponível

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getProcessosJudAdm({ cpf }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cpf, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? processosJudAdmPF : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      !error &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].Processes ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {displayData.Result[0].Processes.Lawsuits.map(
                (lawsuit, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Número
                      </th>
                      <td className="px-3 py-1">{lawsuit.Number}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tipo
                      </th>
                      <td className="px-3 py-1">{lawsuit.Type}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Assunto Principal
                      </th>
                      <td className="px-3 py-1">{lawsuit.MainSubject}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nome do Tribunal
                      </th>
                      <td className="px-3 py-1">{lawsuit.CourtName}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nível do Tribunal
                      </th>
                      <td className="px-3 py-1">{lawsuit.CourtLevel}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tipo de Tribunal
                      </th>
                      <td className="px-3 py-1">{lawsuit.CourtType}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Distrito do Tribunal
                      </th>
                      <td className="px-3 py-1">{lawsuit.CourtDistrict}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Órgão Julgador
                      </th>
                      <td className="px-3 py-1">{lawsuit.JudgingBody}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Estado
                      </th>
                      <td className="px-3 py-1">{lawsuit.State}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <td className="px-3 py-1">{lawsuit.Status}</td>
                    </tr>
                  </React.Fragment>
                )
              )}
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Processos
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.TotalLawsuits}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Processos como Autor
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.TotalLawsuitsAsAuthor}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Processos como Réu
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.TotalLawsuitsAsDefendant}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data do Primeiro Processo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatDate(displayData.Result[0].Processes.FirstLawsuitDate)}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data do Último Processo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatDate(displayData.Result[0].Processes.LastLawsuitDate)}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Processos nos Últimos 30 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.Last30DaysLawsuits}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Processos nos Últimos 90 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.Last90DaysLawsuits}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Processos nos Últimos 180 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.Last180DaysLawsuits}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Processos nos Últimos 365 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Processes.Last365DaysLawsuits}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default ProcessosJA;
