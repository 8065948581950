import React, { useEffect, useState } from "react";
import IAPF from "./IAPF";
import { getIAPF } from "../slices/iaPFSlice";
import { useDispatch, useSelector } from "react-redux";
import CPFForm from "./CPFForm";
import { FcAndroidOs } from "react-icons/fc";

function PFIA() {
  const [cpf, setCpf] = useState("");
  const [submittedCPF, setSubmittedCPF] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);

  const dispatch = useDispatch();
  const { iaPF } = useSelector((state) => state.iaPF);

  // Função para formatar o CPF
  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove qualquer caractere não numérico
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/); // Divide o CPF em grupos de dígitos
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`; // Formata o CPF
    }
    return cleaned; // Retorna o valor limpo se não corresponder ao formato
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "").slice(0, 14);
    setCpf(formatCPF(value)); // Atualiza o CNPJ no contexto
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cpfNumbers = getOnlyNumbers(cpf);
    setSubmittedCPF(cpfNumbers);
    setSubmit(true);
    setSearch(false);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSubmittedCPF(getOnlyNumbers(cpf));
    setSearch(true);
    setSubmit(false);
  };

  useEffect(() => {
    if (search) {
      const fetchData = async () => {
        try {
          if (search) {
            await dispatch(getIAPF({ cpf: submittedCPF }));
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
        setSubmit(false);
      };

      fetchData();
    }
  }, [submit, search, submittedCPF, dispatch]);

  const displayData = search ? iaPF : [];

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-full h-full md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Física - IA Generativa
        </h1>
        <p className="text-gray-300 pb-2">Insira o CPF para obter o Resumo</p>
        <CPFForm
          cpf={cpf}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4 pt-5">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between">
                  <div className="flex items-center gap-2">
                    <FcAndroidOs className="w-10 h-10" />
                    <span>IA Pessoa Física</span>
                  </div>
                  {displayData && (
                    <div className="flex items-center">
                      <span className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data da Consulta
                      </span>
                      <span className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {new Date(displayData.QueryDate).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 pr-1 align-top">
                  <IAPF
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PFIA;
