import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import DadosBasicosEmpresa from "./DadosBasicosEmpresa";
import EvolucaoEmpresa from "./EvolucaoEmpresa";
import Relacoes from "./Relacoes";
import IndicadorAtividades from "./IndicadorAtividades";
import {
  getEvolucaoEmpresa,
  getRelacoes,
} from "../slices/dadosEssenciaisPJSlice";
import { useDispatch, useSelector } from "react-redux";
import NavegacaoPJ from "./NavegacaoPJ";
import CNPJForm from "./CNPJForm";
import { useCNPJ } from "./CNPJContext";
import Relacoes2 from "./Relacoes2";
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import NavegacaoPJAgentes from "./NavegacaoPJAgentes";
import { FaBuilding } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";

function PJDadosEssenciais({ activeTab, setActiveTab, cnpj2 }) {
  const { cnpj, setCnpj } = useCNPJ();
  const [submittedCNPJ, setSubmittedCNPJ] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const { evolucaoPJ, relacoesPJ } = useSelector(
    (state) => state.dadosEssenciaisPJ
  );

  const formatCNPJ = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }
    return cleaned;
  };

  const getOnlyNumbers = (value) => {
    if (typeof value === "string") {
      return value.replace(/\D/g, "");
    }
    return ""; // ou algum valor padrão apropriado
  };

  const handleChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "").slice(0, 14);
    setCnpj(formatCNPJ(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cnpjToUse = getOnlyNumbers(cnpj2 || cnpj);
    setSubmittedCNPJ(cnpjToUse);
    setSubmit(true);
    setSearch(false);
  };

  const handleSearch = useCallback(
    (event) => {
      if (event) event.preventDefault();
      const cnpjToSearch = getOnlyNumbers(cnpj2 || cnpj);
      setSubmittedCNPJ(cnpjToSearch);
      setSearch(true);
      setSubmit(false);
      setInitialSearchDone(true);
    },
    [cnpj, cnpj2]
  );

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
          if (search) {
            await dispatch(getEvolucaoEmpresa({ cnpj: submittedCNPJ }));
            await dispatch(getRelacoes({ cnpj: submittedCNPJ }));
          } else {
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/consultarcnpj`,
              { cnpj: submittedCNPJ }
            );
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError(
            "Erro ao buscar dados. Por favor, tente novamente mais tarde."
          );
        }

        setLoading(false);
        setSubmitted(true);
        setSubmit(false);
      };

      fetchData();
    }
  }, [submit, search, dispatch, submittedCNPJ]);

  // const handleChange = (event) => {
  //   let value = event.target.value;
  //   value = value.replace(/\D/g, "").slice(0, 14);
  //   setCnpj(formatCNPJ(value));
  // };
  
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const cnpjToUse = getOnlyNumbers(cnpj2 || cnpj);
  //   setSubmittedCNPJ(cnpjToUse);
  //   setSubmit(true);
  //   setSearch(false);
  // };
  
  // const handleSearch = useCallback(
  //   (event) => {
  //     if (event) event.preventDefault();
  //     const cnpjToSearch = getOnlyNumbers(cnpj2 || cnpj);
  //     setSubmittedCNPJ(cnpjToSearch);
  //     setSearch(true);
  //     setSubmit(false);
  //     setInitialSearchDone(true);
  //   },
  //   [cnpj, cnpj2]
  // );
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     setError(null);
  
  //     try {
  //       if (search) {
  //         await dispatch(getEvolucaoEmpresa({ cnpj: submittedCNPJ }));
  //         await dispatch(getRelacoes({ cnpj: submittedCNPJ }));
  //       } else {
  //         await axios.post(
  //           `${process.env.REACT_APP_API_BASE_URL}/consultarcnpj`,
  //           { cnpj: submittedCNPJ }
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Erro ao buscar dados:", error);
  //       setError(
  //         "Erro ao buscar dados. Por favor, tente novamente mais tarde."
  //       );
  //     }
  
  //     setLoading(false);
  //     setSubmitted(true);
  //     setSubmit(false);
  
  //     // Dispara a busca após o submit
  //     if (submit) {
  //       handleSearch();
  //     }
  //   };
  
  //   if (submit || search) {
  //     fetchData();
  //   }
  // }, [submit, search, dispatch, submittedCNPJ]);

  useEffect(() => {
    if (
      (getOnlyNumbers(cnpj).length === 14 ||
        getOnlyNumbers(cnpj2).length === 14) &&
      !initialSearchDone
    ) {
      handleSearch();
    }
  }, [cnpj, cnpj2, handleSearch, initialSearchDone]);

  const displayData = search ? evolucaoPJ : [];

  const currentViewIsAgentes = location.pathname.includes("agentes-seb");

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      <div className="flex flex-col justify-center items-center w-full h-full px-3 md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Jurídica - Dados Essenciais
        </h1>
        <p className="text-gray-300 pb-2">
          Insira o CNPJ para obter os dados essenciais
        </p>
        <CNPJForm
          cnpj={cnpj2 || cnpj}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="flex justify-end w-full">
          {currentViewIsAgentes ? (
            <NavegacaoPJAgentes
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <NavegacaoPJ />
          )}
        </div>
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100 relative">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaBuilding className="w-10 h-10 text-blue-500" />
                    <span>Dados Básicos da Empresa</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between">
                  <span className="flex items-center gap-2">
                    <FaDollarSign className="w-10 h-10 text-yellow-500" /> Dados Financeiros{" "}
                  </span>
                  {displayData && (
                    <div className="flex items-center absolute top-0 right-0">
                      <span className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data da Consulta
                      </span>
                      <span className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {new Date(displayData.QueryDate).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <DadosBasicosEmpresa
                    cnpj={submittedCNPJ}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <EvolucaoEmpresa
                    cnpj={submittedCNPJ}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaChartLine className="w-10 h-10 text-green-600" />
                    <span>Indicadores de Atividade</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaHandshake className="w-10 h-10 text-red-600" />
                    <span>Sócios e Administradores</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <IndicadorAtividades
                    cnpj={submittedCNPJ}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <Relacoes
                    relacoes={relacoesPJ} // Passa os dados para o componente filho
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaUsers className="w-10 h-10 text-indigo-500" />
                    <span>CNPJs do Grupo</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="bg-gray-100">
                <td className="border-b border-gray-200 p-1 align-top">
                  <Relacoes2
                    cnpj={submittedCNPJ}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PJDadosEssenciais;
