import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getProcessosJudAdmFamily } from "../slices/processosPFSlice";

function ProcessosJAF({ cpf, search }) {
  const dispatch = useDispatch();
  const { processosJudAdmFamilyPF } = useSelector((state) => state.processosPF);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getProcessosJudAdmFamily({ cpf }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cpf, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? processosJudAdmFamilyPF : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      !error &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].FirstLevelRelativesLawsuitsData &&
      displayData.Result[0].FirstLevelRelativesLawsuitsData.length > 0 ? (
        <div className="table-responsive max-w-md">
          {displayData.Result[0].FirstLevelRelativesLawsuitsData.map(
            (item, index) => (
              <div key={index} className="mb-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Número do Documento
                      </th>
                      <td className="px-3 py-1">{item.DocNumber}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tipo de Relacionamento
                      </th>
                      <td className="px-3 py-1">{item.RelationshipType}</td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total de Processos
                      </th>
                      <td className="px-3 py-1">
                        {item.LawsuitsData.TotalLawsuits}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total de Processos como Autor
                      </th>
                      <td className="px-3 py-1">
                        {item.LawsuitsData.TotalLawsuitsAsAuthor}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total de Processos como Réu
                      </th>
                      <td className="px-3 py-1">
                        {item.LawsuitsData.TotalLawsuitsAsDefendant}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total de Processos como Outro
                      </th>
                      <td className="px-3 py-1">
                        {item.LawsuitsData.TotalLawsuitsAsOther}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default ProcessosJAF;
