import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getRiscoFinanceiro } from "../slices/scorePFSlice";

function RiscoFinanceiro({ cpf, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { riscoFinanceiroPF } = useSelector((state) => state.scorePF);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getRiscoFinanceiro({ cpf }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cpf, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? riscoFinanceiroPF : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && error && <p className="text-red-600">Erro: {error}</p>}
      {!loading &&
      submitted &&
      !error &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].FinancialRisk ? (
        <div className="table-responsive">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Ativos
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.TotalAssets}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Faixa de Renda Estimada
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.EstimatedIncomeRange}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Atualmente Empregado
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.IsCurrentlyEmployed
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Atualmente Proprietário
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.IsCurrentlyOwner
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data de Início do Último Emprego
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.LastOccupationStartDate
                    ? new Date(
                        displayData.Result[0].FinancialRisk.LastOccupationStartDate
                      ).toLocaleDateString()
                    : "Não disponível"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Atualmente em Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.IsCurrentlyOnCollection
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de Cobrança nos Últimos 365 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].FinancialRisk
                      .Last365DaysCollectionOccurrences
                  }
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Meses Consecutivos Atuais de Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].FinancialRisk
                      .CurrentConsecutiveCollectionMonths
                  }
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Atualmente Recebendo Assistência
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk
                    .IsCurrentlyReceivingAssistance
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Pontuação de Risco Financeiro
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.FinancialRiskScore}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nível de Risco Financeiro
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].FinancialRisk.FinancialRiskLevel}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default RiscoFinanceiro;
