import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getPresCob } from "../slices/scorePFSlice";

function PresCob({ cpf, search }) {
  const dispatch = useDispatch();
  const { presCobPF } = useSelector((state) => state.scorePF);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getPresCob({ cpf }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cpf, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? presCobPF : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && !error && displayData && displayData.Result && displayData.Result[0] && displayData.Result[0].Collections ? (
        <div className="table-responsive max-w-md">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Está em cobrança?
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.IsCurrentlyOnCollection
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de cobrança nos últimos 30 dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.Last30DaysCollectionOccurrences}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de cobrança nos últimos 90 dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.Last90DaysCollectionOccurrences}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de cobrança nos últimos 180 dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.Last180DaysCollectionOccurrences}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de cobrança nos últimos 365 dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.Last365DaysCollectionOccurrences}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading && submitted && !error && (
          <p>Não foram encontrados dados para renderizar. Por favor, verifique a resposta da API.</p>
        )
      )}
    </div>
  );
}

export default PresCob;
