import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getEvolucaoEmpresa } from "../slices/dadosEssenciaisPJSlice";

function EvolucaoEmpresa({ cnpj, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { evolucaoPJ } = useSelector((state) => state.dadosEssenciaisPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // const removerZerosEsquerda = (str) => {
  //   return str
  //     .split(" ")
  //     .map((part) => part.replace(/^0+/, "")) // Remove os zeros à esquerda de cada parte
  //     .join(" "); // Reconstrua a string com as partes formatadas
  // };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getEvolucaoEmpresa({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };

    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? evolucaoPJ : [];

  function translateGrowthStatus(status) {
    switch (status) {
      case "DECREASED":
        return "Diminuiu";
      case "GROW UP":
        return "Cresceu";
      case "STABLE":
        return "Estável";
      default:
        return "Não encontrado";
    }
  }

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].CompanyEvolutionData ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Capital Mínimo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatarCapital(
                    displayData.Result[0].CompanyEvolutionData.MinCapital
                  ) || "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Capital Máximo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatarCapital(
                    displayData.Result[0].CompanyEvolutionData.MaxCapital
                  ) || "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Mínima Funcionários
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MinQtyEmployees !== undefined
                    ? displayData.Result[0].CompanyEvolutionData.MinQtyEmployees
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Máxima Funcionários
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MaxQtyEmployees !== undefined
                    ? displayData.Result[0].CompanyEvolutionData.MaxQtyEmployees
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Mínima Subsidiárias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MinQtySubsidiaries !== undefined
                    ? displayData.Result[0].CompanyEvolutionData
                        .MinQtySubsidiaries
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Máxima Subsidiárias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MaxQtySubsidiaries !== undefined
                    ? displayData.Result[0].CompanyEvolutionData
                        .MaxQtySubsidiaries
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Mínima Sócios
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData.MinQtyQSA !==
                  undefined
                    ? displayData.Result[0].CompanyEvolutionData.MinQtyQSA
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Máxima Sócios
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData.MaxQtyQSA !==
                  undefined
                    ? displayData.Result[0].CompanyEvolutionData.MaxQtyQSA
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nível de atividade Mínimo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MinActivityLevel !== undefined
                    ? displayData.Result[0].CompanyEvolutionData.MinActivityLevel.toFixed(
                        2
                      )
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nível de atividade Máxima
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].CompanyEvolutionData
                    .MaxActivityLevel !== undefined
                    ? displayData.Result[0].CompanyEvolutionData.MaxActivityLevel.toFixed(
                        2
                      )
                    : "Não encontrado"}
                </td>
              </tr>
              {displayData.Result[0].CompanyEvolutionData.DataHistoryOverTime &&
                displayData.Result[0].CompanyEvolutionData.DataHistoryOverTime.slice(
                  0,
                  1
                ).map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Descrição da Atividade Principal
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {item.MainActivityDescription || "Não encontrado"}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}

              <tr>
                <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Taxa de Crescimento 1 ano atrás
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {translateGrowthStatus(
                    displayData.Result[0].CompanyEvolutionData
                      .YearOverYearGrowthRateStatus1YearAgo
                  )}
                </td>
              </tr>
              <tr>
                <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Taxa de Crescimento 3 anos atrás
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {translateGrowthStatus(
                    displayData.Result[0].CompanyEvolutionData
                      .YearOverYearGrowthRateStatus3YearsAgo
                  )}
                </td>
              </tr>
              <tr>
                <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Taxa de Crescimento 5 anos atrás
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {translateGrowthStatus(
                    displayData.Result[0].CompanyEvolutionData
                      .YearOverYearGrowthRateStatus5YearsAgo
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default EvolucaoEmpresa;
