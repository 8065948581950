import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getFinanceiro } from "../slices/dadosEssenciaisPFSlice";

function Financeiro({ cpf, search }) {
  const dispatch = useDispatch();
  const { financeiroPF } = useSelector((state) => state.dadosEssenciaisPF);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getFinanceiro({ cpf }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cpf, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? financeiroPF : [];

  // Cria uma cópia dos dados e faz a ordenação
  const sortedTaxReturns = displayData?.Result?.[0]?.FinantialData?.TaxReturns
    ? [...displayData.Result[0].FinantialData.TaxReturns]
        .sort((a, b) => b.Year - a.Year)
        .slice(0, 6)
    : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && !error && displayData && displayData.Result && displayData.Result[0] && displayData.Result[0].FinantialData ? (
        <div className="max-w-4xl">
          <h2 className="p-1 font-bold text-sm text-gray-500">
            RETORNO DE IMPOSTOS
          </h2>
          {sortedTaxReturns.map((taxReturn, index) => (
            <div key={index} className="mb-4">
              <table className="w-full min-w-full divide-y divide-gray-200 text-left">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <th className="w-2/4 px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ano
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {taxReturn.Year}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {taxReturn.Status}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Banco
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {taxReturn.Bank}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data de Captura
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {new Date(taxReturn.CaptureDate).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          <h2 className="p-1 font-bold text-sm text-gray-500 mt-4">
            ESTIMATIVAS DE RENDA
          </h2>
          <div className="mb-4">
            <table className="w-full min-w-full divide-y divide-gray-200 text-left">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <th className="w-2/4 px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Dono de Empresa
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {displayData.Result[0].FinantialData.IncomeEstimates['COMPANY OWNERSHIP']}
                  </td>
                </tr>
                <tr>
                  <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    MTE
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {displayData.Result[0].FinantialData.IncomeEstimates['MTE']}
                  </td>
                </tr>
                <tr>
                  <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    IBGE
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {displayData.Result[0].FinantialData.IncomeEstimates['IBGE']}
                  </td>
                </tr>
                <tr>
                  <th className="px-3 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    MODELO
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {displayData.Result[0].FinantialData.IncomeEstimates['BIGDATA']}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        !loading && submitted && !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default Financeiro;
