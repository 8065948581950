import React from "react";
import { TailSpin } from "react-loader-spinner";

function Relacoes({ relacoes, search }) {
  const formatDocument = (number) => {
    if (!number) return "Não encontrado";

    const cleanNumber = number.replace(/\D+/g, "");

    if (cleanNumber.length === 11) {
      return cleanNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleanNumber.length === 14) {
      return cleanNumber.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      return "Não encontrado";
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString);
  };

  const filterData = (data) => {
    if (!data || !data.Result || !data.Result[0] || !data.Result[0].Relationships) return [];
    const relationships = data.Result[0].Relationships.Relationships || [];
    const queryDate = parseDate(data.QueryDate);

    return relationships
      .filter((rel) => rel.RelationshipType === "QSA")
      .filter((rel) => {
        const endDate = parseDate(rel.RelationshipEndDate);
        return endDate && queryDate && endDate > queryDate;
      });
  };

  return (
    <div>
      {search && !relacoes && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!search && !relacoes && (
        <p>Não foram encontrados dados para renderizar. Por favor, verifique a resposta da API.</p>
      )}
      {relacoes && relacoes.Result && relacoes.Result[0] && relacoes.Result[0].Relationships && (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white">
              {filterData(relacoes).map((rel, index) => (
                <React.Fragment key={index}>
                  <tr className={index > 0 ? "border-t border-gray-200" : ""}>
                    <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      CPF/CNPJ
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {formatDocument(rel.RelatedEntityTaxIdNumber) || "Não encontrado"}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {rel.RelatedEntityName || "Não encontrado"}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cargo
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {rel.RelationshipName || "Não encontrado"}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Relacoes;
