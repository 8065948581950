import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import PFDadosEssenciais from "./components/PFDadosEssenciais";
import PFScore from "./components/PFScore";
import PFProcessos from "./components/PFProcessos";
import PFIA from "./components/PFIA";
import PJDadosEssenciais from "./components/PJDadosEssenciais";
import PJScore from "./components/PJScore";
import PJProcessos from "./components/PJProcessos";
import PJIA from "./components/PJIA";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import PJProtestos from "./components/PJProtestos";
import Agentes from "./pages/Agentes/Agentes";
import Monitoring from "./pages/Monitoring/Monitoring";
import Historico from "./pages/Historico/Historico";
import Pending from "./pages/Pending/Pending";

function App() {
  const [open, setOpen] = useState(false);

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Router>
          <div className="bg-gradient-to-tr from-gray-600 via-blue-700 to-gray-800 flex h-screen">
            <div>
              <Sidebar open={open} setOpen={setOpen} />
            </div>

            <div className="flex flex-col w-full pl-20">
              <div>
                <Header signOut={signOut} user={user} />
              </div>
              <div className="flex-grow pl-1 pt-5 pb-5 bg-gradient-to-tr from-gray-600 via-blue-700 to-gray-800">
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Historico />} />
                  <Route path="/pending" element={<Pending />} />
                  <Route path="/risk-management/controlling" element={<Dashboard />} />
                  {/* <Route path="/risk-management/agentes-seb" element={<Agentes />} /> */}
                  <Route path="/risk-management/monitoring" element={<Monitoring />} />
                  <Route
                    path="/pessoa-fisica/dados-essenciais"
                    element={<PFDadosEssenciais />}
                  />
                  <Route path="/pessoa-fisica/score" element={<PFScore />} />
                  <Route
                    path="/pessoa-fisica/processos"
                    element={<PFProcessos />}
                  />
                  <Route path="/pessoa-fisica/IA" element={<PFIA />} />
                  <Route
                    path="/pessoa-juridica/dados-essenciais"
                    element={<PJDadosEssenciais />}
                  />
                  <Route path="/pessoa-juridica/score" element={<PJScore />} />
                  <Route
                    path="/pessoa-juridica/processos"
                    element={<PJProcessos />}
                  />
                  <Route path="/pessoa-juridica/IA" element={<PJIA />} />
                  <Route path="/pessoa-juridica/protestos" element={<PJProtestos />} />
                </Routes>
              </div>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </Router>
      )}
    </Authenticator>
  );
}

export default App;
