import { api, requestConfig } from "../utils/config";

const getDashboard = async (data1, data2, operationType) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dashboard?data1=${data1}&data2=${data2}&operationType=${operationType}`);
    const res = await fetch(api + `/dashboard?data1=${data1}&data2=${data2}&operationType=${operationType}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getDashboard2 = async (data1, score) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dashboard2?data1=${data1}&score=${score}`);
    const res = await fetch(api + `/dashboard2?data1=${data1}&score=${score}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getClienteScore = async (ano_mes) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/cliente_score?ano_mes=${ano_mes}`);
    const res = await fetch(api + `/cliente_score?ano_mes=${ano_mes}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getClienteScorePending = async (anoMes, opinion1, opinion2) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    // Construção da URL base
    let url = `${api}/cliente_score_pending`;

    // Adiciona anoMes, opinion1 e opinion2 como query params se existirem
    const params = new URLSearchParams();
    if (anoMes) params.append("anoMes", anoMes);
    if (opinion1) params.append("opinion1", opinion1);
    if (opinion2) params.append("opinion2", opinion2);

    // Se houver parâmetros, adiciona-os à URL
    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    console.log("URL da solicitação:", url);
    const res = await fetch(url, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};


const updateClienteScore = async (counterpartyCnpj, opinion2, observations, limitValue, expiryDate) => {
  const config = requestConfig("PUT", {
    opinion2: opinion2,
    observations: observations,
    limitValue: limitValue,
    expiryDate: expiryDate
  }); // Reutilizando a requestConfig para definir o método PUT e o corpo da requisição

  try {
    console.log("URL da solicitação:", api + `/cliente_score/${counterpartyCnpj}`);
    const res = await fetch(api + `/cliente_score/${counterpartyCnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao atualizar cliente score:", error);
    return null;
  }
};

const dashboardService = {
  getDashboard,
  getDashboard2,
  getClienteScore,
  getClienteScorePending,
  updateClienteScore
};

export default dashboardService;