// CPFContext.js
import React, { createContext, useContext, useState } from 'react';

const CPFContext = createContext();

export const CPFProvider = ({ children }) => {
  const [cpf, setCpf] = useState("");

  return (
    <CPFContext.Provider value={{ cpf, setCpf }}>
      {children}
    </CPFContext.Provider>
  );
};

export const useCPF = () => {
  return useContext(CPFContext);
};
