import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { getDashboard } from "../../slices/dashboardSlice";

const DashboardChart = ({
  mwh,
  mtm,
  score,
  menorY1,
  maiorY1,
  total,
  dataSelecionada,
  dataSelecionada2,
  operationType,
}) => {
  const { dashboards } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const [counterpartyTypes, setCounterpartyTypes] = useState([]);
  const [selectedCounterpartyTypes, setSelectedCounterpartyTypes] = useState(
    []
  );

  useEffect(() => {
    if (dataSelecionada || dataSelecionada2) {
      dispatch(
        getDashboard({
          data1: dataSelecionada,
          data2: dataSelecionada2,
          operationType,
        })
      );
    }
  }, [dispatch, dataSelecionada, dataSelecionada2, operationType]);

  useEffect(() => {
    if (dashboards && dashboards.length > 0) {
      const uniqueCounterpartyTypes = [
        ...new Set([
          ...dashboards.map((d) => d.counterpartyType1),
          ...dashboards.map((d) => d.counterpartyType2),
        ]),
      ];
      setCounterpartyTypes(uniqueCounterpartyTypes);
      setSelectedCounterpartyTypes(uniqueCounterpartyTypes); // Seleciona todos por padrão
    }
  }, [dashboards]);

  const handleCounterpartyTypeChange = (type) => {
    if (selectedCounterpartyTypes.includes(type)) {
      setSelectedCounterpartyTypes((prev) => prev.filter((t) => t !== type));
    } else {
      setSelectedCounterpartyTypes((prev) => [...prev, type]);
    }
  };

  if (!dashboards || dashboards.length === 0) {
    return <p className="text-black">Carregando dados...</p>;
  }

  const filteredDashboards = dashboards.filter(
    (d) =>
      selectedCounterpartyTypes.includes(d.counterpartyType1) ||
      selectedCounterpartyTypes.includes(d.counterpartyType2)
  );

  const vectorCategories = [];
  if (menorY1) vectorCategories.push("<= Y1");
  if (maiorY1) vectorCategories.push("> Y1");
  if (total) vectorCategories.push("Total");

  const scores = ["A", "B", "C", "Total"];

  // Inicializar estrutura para armazenar os dados
  const data = {
    "<= Y1": { A: [0, 0], B: [0, 0], C: [0, 0], Total: [0, 0] },
    "> Y1": { A: [0, 0], B: [0, 0], C: [0, 0], Total: [0, 0] },
    Total: { A: [0, 0], B: [0, 0], C: [0, 0], Total: [0, 0] },
  };

  // Função para processar os dados do dashboard
  const processDashboardData = (dashboard, isSecondSet = false) => {
    const index = isSecondSet ? 1 : 0; // 0 para data1, 1 para data2
    const scoreKey = isSecondSet ? "score2" : "score1";
    const vectorCategoryKey = isSecondSet
      ? "vector_category2"
      : "vector_category1";
    const MWhKey = isSecondSet ? "MWh2" : "MWh1";
    const MtmKey = isSecondSet ? "Mtm2" : "Mtm1";
    const counterpartyNameKey = isSecondSet
      ? "counterpartyName2"
      : "counterpartyName1";

    const scoreValue = dashboard[scoreKey];
    const vectorCategoryValue = dashboard[vectorCategoryKey];
    const MWhValue = dashboard[MWhKey];
    const MtmValue = dashboard[MtmKey];
    const counterpartyNameValue = dashboard[counterpartyNameKey];

    if (score) {
      if (!data[vectorCategoryValue]) {
        data[vectorCategoryValue] = {
          A: [0, 0],
          B: [0, 0],
          C: [0, 0],
          Total: [0, 0],
        };
      }

      // Usar Set para contar contrapartes distintas
      // Usar Set para contar contrapartes distintas
      if (!data[vectorCategoryValue]) {
        data[vectorCategoryValue] = {};
      }

      if (!data[vectorCategoryValue][scoreValue]) {
        data[vectorCategoryValue][scoreValue] = {};
      }

      if (!data[vectorCategoryValue][scoreValue].counterparties) {
        data[vectorCategoryValue][scoreValue].counterparties = [
          new Set(),
          new Set(),
        ];
      }

      data[vectorCategoryValue][scoreValue].counterparties[index].add(
        counterpartyNameValue
      );
      data[vectorCategoryValue][scoreValue][index] =
        data[vectorCategoryValue][scoreValue].counterparties[index].size;
    } else {
      if (
        data[vectorCategoryValue] &&
        data[vectorCategoryValue][scoreValue] !== undefined
      ) {
        if (mwh) {
          data[vectorCategoryValue][scoreValue][index] += MWhValue;
        } else if (mtm) {
          data[vectorCategoryValue][scoreValue][index] += MtmValue;
        }
      }
    }
  };

  // Processar os dados para ambos os conjuntos de dados (data1 e data2)
  filteredDashboards.forEach((dashboard) => {
    processDashboardData(dashboard, false); // Primeiro conjunto de dados
    processDashboardData(dashboard, true); // Segundo conjunto de dados
  });

  // Calcular o total geral para cada categoria
  vectorCategories.forEach((vector) => {
    scores.slice(0, -1).forEach((score) => {
      // Ignorar "Total" para o cálculo
      data[vector]["Total"][0] += data[vector][score][0] || 0;
      data[vector]["Total"][1] += data[vector][score][1] || 0;
    });
  });

  // Preparar as séries de dados para o gráfico
  const series = vectorCategories.flatMap((vector) => [
    {
      name: `${vector} (Data Anterior)`,
      data: scores.map((score) => data[vector][score][0] || 0),
    },
    {
      name: `${vector} (Data Processo)`,
      data: scores.map((score) => data[vector][score][1] || 0),
    },
  ]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "33%",
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF4560", "#00E396", "#008FFB", "#FEB019"],
    xaxis: {
      categories: scores,
      title: {
        text: "Score",
      },
      labels: {
        show: true,
        style: {
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      title: {
        text: mwh ? "MWh" : mtm ? "MtM" : "Quantidade",
      },
      labels: {
        formatter: function (val) {
          return val.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    grid: {
      show: true,
    },
  };

  return (
    <div className="w-full px-5">
      <h1 className="text-black text-center text-2xl pt-5 pb-5">
        Gráfico Score
      </h1>
      <div className="flex items-center justify-center mb-5">
        <div className="flex flex-wrap">
          {counterpartyTypes.map((type) => (
            <label key={type} className="inline-flex items-center mr-4 mb-2">
              <input
                type="checkbox"
                value={type}
                checked={selectedCounterpartyTypes.includes(type)}
                onChange={() => handleCounterpartyTypeChange(type)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              <span className="ml-2 text-gray-700">{type}</span>
            </label>
          ))}
        </div>
      </div>
      <div id="chart" className="w-full">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default DashboardChart;
