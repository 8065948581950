import React, { useEffect, useState, useCallback } from "react";
import PresCob from "./PresCob";
import ProbNeg from "./ProbNeg";
import RiscoFinanceiro from "./RiscoFinanceiro";
import NavegacaoPF from "./NavegacaoPF";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getRiscoFinanceiro } from "../slices/scorePFSlice";
import CPFForm from "./CPFForm";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { useCPF } from "./CPFContext";
import { TailSpin } from "react-loader-spinner";

function PFScore() {
  const { cpf, setCpf } = useCPF();
  const [submittedCPF, setSubmittedCPF] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { riscoFinanceiroPF } = useSelector((state) => state.scorePF);

  // Função para formatar o CPF
  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove qualquer caractere não numérico
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/); // Divide o CPF em grupos de dígitos
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`; // Formata o CPF
    }
    return cleaned; // Retorna o valor limpo se não corresponder ao formato
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "").slice(0, 14);
    setCpf(formatCPF(value)); // Atualiza o CNPJ no contexto
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cpfNumbers = getOnlyNumbers(cpf);
    setSubmittedCPF(cpfNumbers);
    setSubmit(true);
    setSearch(false);
  };

  const handleSearch = useCallback(
    (event) => {
      if (event) event.preventDefault();
      const cpfToSearch = getOnlyNumbers(cpf);
      setSubmittedCPF(cpfToSearch);
      setSearch(true);
      setSubmit(false);
      setInitialSearchDone(true);
    },
    [cpf]
  );

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
          if (search) {
            await dispatch(getRiscoFinanceiro({ cpf: submittedCPF }));
          } else {
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/consultarcpf`,
              { cpf: submittedCPF }
            );
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError(
            "Erro ao buscar dados. Por favor, tente novamente mais tarde."
          );
        }

        setLoading(false);
        setSubmitted(true);
        setSubmit(false);
      };

      fetchData();
    }
  }, [submit, search, dispatch, submittedCPF]);

  useEffect(() => {
    if (getOnlyNumbers(cpf).length === 11 && !initialSearchDone) {
      handleSearch();
    }
  }, [cpf, handleSearch, initialSearchDone]);

  const displayData = search ? riscoFinanceiroPF : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      <div className="flex flex-col justify-center items-center w-full h-full px-3 md:px-0">
        <h1 className="text-xl font-bold text-white">Pessoa Física - Score</h1>
        <p className="text-gray-300 pb-2">Insira o CPF para obter o Score</p>
        <CPFForm
          cpf={cpf}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="flex justify-end w-full">
          <NavegacaoPF />
        </div>
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4">
          <table className="w-full table-fixed text-left">
            <thead>
              <tr className="bg-gray-100 relative">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaFileInvoiceDollar className="w-10 h-10 text-indigo-300" />
                    <span>Presença em Cobrança</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaExclamationTriangle className="w-10 h-10 text-[#FF0000]" />
                    <span>Probabilidade Negativação</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between">
                  <span className="flex items-center gap-2">
                    <FaWallet className="w-10 h-10 text-[#FFD700]" />
                    Dados Financeiros
                  </span>
                  {displayData && (
                    <div className="flex items-center absolute top-0 right-0">
                      <span className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                        Data da Consulta
                      </span>
                      <span className="px-1 py-1 font-semibold text-sm text-gray-800">
                        {new Date(displayData.QueryDate).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <PresCob
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <ProbNeg
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <RiscoFinanceiro
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PFScore;
