import { api, requestConfig } from "../utils/config";

const getPresCobPJ = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/pres_cob_pj/${cnpj}`);
    const res = await fetch(api + `/pres_cob_pj/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getScoreQuod = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/score_quod/${cnpj}`);
    const res = await fetch(api + `/score_quod/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getScoreQuodDashboard = async (anoMes = "") => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    // Se anoMes não estiver presente, não adicione o parâmetro à URL
    const url = anoMes ? `${api}/score_quod_dashboard?anoMes=${anoMes}` : `${api}/score_quod_dashboard`;
    console.log("URL da solicitação:", url);
    const res = await fetch(url, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const scorePFService = {
  getPresCobPJ,
  getScoreQuod,
  getScoreQuodDashboard
};

export default scorePFService;