import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getScoreQuod } from "../slices/scorePJSlice";
import Speedometer from "react-d3-speedometer";
import {
  getDadosBasicosEmpresa,
  getDadosBasicosEmpresaHistorico,
  getDividas,
  getEvolucaoEmpresa,
} from "../slices/dadosEssenciaisPJSlice";
import { getProcessosEmpresas } from "../slices/processosPJSlice";

import approved from "../images/approved.png";
import rejected from "../images/rejected.png";
import pending from "../images/pending.png";
import { getProtestosPJ } from "../slices/protestosPJSlice";

function ScoreVelocimetro({ cnpj, search }) {
  const dispatch = useDispatch();
  const { scoreQuodPJ } = useSelector((state) => state.scorePJ);
  const { dadosBasicosPJ, dadosBasicosHistoricoPJ, evolucaoPJ, dividaPJ } =
    useSelector((state) => state.dadosEssenciaisPJ);
  const { processosEmpresasPJ } = useSelector((state) => state.processosPJ);
  const { protestosPJ } = useSelector((state) => state.protestosPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        await Promise.all([
          dispatch(getScoreQuod({ cnpj })),
          dispatch(getEvolucaoEmpresa({ cnpj })),
          dispatch(getProcessosEmpresas({ cnpj })),
          dispatch(getDadosBasicosEmpresa({ cnpj })),
          dispatch(getDadosBasicosEmpresaHistorico({ cnpj })),
          dispatch(getDividas({ cnpj })),
          dispatch(getProtestosPJ({ cnpj })),
        ]);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };

    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);
  
  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? scoreQuodPJ : [];
  const evolucaoData = search ? evolucaoPJ : [];
  const basicosData = search ? dadosBasicosPJ : [];
  const historyData = search ? dadosBasicosHistoricoPJ : [];
  const dividasData = search ? dividaPJ : [];
  const protestosData = search ? protestosPJ : [];

  // Obtém o score ou define como 0 se não estiver disponível
  const score =
    displayData &&
    displayData.Result &&
    displayData.Result[0] &&
    displayData.Result[0].QUODCreditScoreCompany
      ? displayData.Result[0].QUODCreditScoreCompany.Score
      : 0;

  // Função para classificar o capital
  function classificarCapital(valor) {
    if (valor < 100000) {
      return "< 100K";
    } else if (valor < 1000000) {
      return "100K a < 1M";
    } else if (valor < 10000000) {
      return "1M a < 10M";
    } else if (valor < 50000000) {
      return "10M a < 50M";
    } else if (valor < 100000000) {
      return "50M a < 100M";
    } else if (valor >= 100000000) {
      return "> 100M";
    } else {
      return "Acima de 50M";
    }
  }

  const processosEmpresaData = search ? processosEmpresasPJ : [];

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const processosEmpresaLawsuits =
    processosEmpresaData?.Result?.[0]?.Lawsuits?.Lawsuits || [];

  // Cria uma cópia do array para ordenar
  const sortedLawsuits = [...processosEmpresaLawsuits].sort(
    (a, b) => new Date(b.LastUpdate) - new Date(a.LastUpdate)
  );

  // Extrai o número do MatchKeys e remove o caractere final }
  const matchKeysString = processosEmpresaData?.Result?.[0]?.MatchKeys || null;
  const matchKeys = matchKeysString
    ? matchKeysString.replace(/^\D+|\D+$/g, "")
    : null;

  // Função para comparar strings ignorando maiúsculas e minúsculas
  const normalizeString = (str) => (str ? str.toLowerCase() : "");

  // Verifica o Doc e a Polarity em todos os objetos de Parties
  const findMatchingDocAndPolarity = (parties) => {
    for (const party of parties) {
      const partyDoc = party?.Doc ? party.Doc.replace(/^\D+/g, "") : null;
      const partyPolarity = normalizeString(party?.Polarity);
      if (partyDoc === matchKeys && partyPolarity === "passive") {
        return true;
      }
    }
    return false;
  };

  // Cria um objeto para armazenar totais por Status
  const statusTotals = {};

  // Função para normalizar Status
  const normalizeStatus = (status) => (status ? status.toLowerCase() : "");

  // Filtra e soma valores somente quando a Polarity for 'Passive' e o Doc corresponde ao MatchKeys
  sortedLawsuits.forEach((lawsuit) => {
    if (findMatchingDocAndPolarity(lawsuit?.Parties || [])) {
      const lawsuitPolarity = normalizeString(
        lawsuit?.Parties?.find(
          (party) => party?.Doc && party?.Doc.replace(/^\D+/g, "") === matchKeys
        )?.Polarity
      );

      if (lawsuitPolarity === "passive") {
        const lawsuitStatus = normalizeStatus(lawsuit?.Status);
        if (statusTotals[lawsuitStatus]) {
          statusTotals[lawsuitStatus] += lawsuit.Value;
        } else {
          statusTotals[lawsuitStatus] = lawsuit.Value;
        }
      }
    }
  });

  // Total geral de Passive
  const totalValue = sortedLawsuits
    .filter((lawsuit) => findMatchingDocAndPolarity(lawsuit?.Parties || []))
    .reduce((total, lawsuit) => {
      const lawsuitPolarity = normalizeString(
        lawsuit?.Parties?.find(
          (party) => party?.Doc && party?.Doc.replace(/^\D+/g, "") === matchKeys
        )?.Polarity
      );
      return total + (lawsuitPolarity === "passive" ? lawsuit.Value : 0);
    }, 0);

  const valorStatus = totalValue > 50000 ? "Não OK" : "OK";

  const getStatusImage = (score, userDecision) => {
    if (userDecision) {
      return userDecision === "approved" ? approved : rejected;
    }
    if (score >= 300 && score <= 500) {
      return rejected;
    } else if (score >= 501 && score <= 700) {
      return pending;
    } else if (score >= 701 && score <= 1000) {
      return approved;
    }
    return null; // Caso o score esteja fora do intervalo esperado
  };

  const totalProtestos = protestosData?.[0]?.cartorios
  ? Object.values(protestosData[0].cartorios).reduce((total, estado) => {
      return (
        total +
        Object.values(estado).reduce((subtotal, cartorio) => {
          return (
            subtotal +
            (cartorio.protestos
              ? cartorio.protestos.reduce((protestoTotal, protesto) => {
                  return protestoTotal + (protesto.valor ? protesto.valor : 0);
                }, 0)
              : 0)
          );
        }, 0)
      );
    }, 0)
  : 0;

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && (
        <div>
          {displayData?.Result?.[0]?.QUODCreditScoreCompany ? (
            <>
              <div className="px-3">
                <p className="font-bold ">Score: {score}</p>
                <Speedometer
                  maxValue={1000}
                  minValue={300}
                  value={score}
                  needleColor="black"
                  customSegmentStops={[300, 500, 600, 700, 900, 1000]}
                  segmentColors={[
                    "red",
                    "orange",
                    "yellow",
                    "lightgreen",
                    "green",
                  ]}
                  height={300}
                  width={500}
                />
              </div>
              <div className="flex">
                <div className="flex">
                  {evolucaoData?.Result?.[0]?.CompanyEvolutionData
                    ?.DataHistoryOverTime?.length > 0 ? (
                    <>
                      <div className="whitespace-nowrap px-3">
                        {basicosData?.Result?.[0]?.BasicData ? (
                          <p className="uppercase text-xl font-bold mb-3">
                            Capital:{" "}
                            <strong>
                              {classificarCapital(
                                basicosData.Result[0].BasicData
                                  .AdditionalOutputData.CapitalRS
                              ) || "Não encontrado"}
                            </strong>
                          </p>
                        ) : (
                          <p>Capital: Dados não disponíveis</p>
                        )}
                        <h2 className="font-bold">RESTRIÇÕES</h2>

                        <p>
                          Valor Processos &lt; 50K:{" "}
                          <strong>{valorStatus}</strong>
                        </p>
                        <p>
      Valor Protestos &lt; 50K: <strong>{totalProtestos < 50000 ? "Ok" : "Não OK"}</strong>
    </p>
                        {basicosData?.Result?.[0]?.BasicData ? (
                          <p>
                            Situação Cadastral:{" "}
                            <strong>
                              {basicosData.Result[0].BasicData.TaxIdStatus ===
                              "ATIVA"
                                ? "Ok"
                                : "Não Ok"}
                            </strong>
                          </p>
                        ) : (
                          <p>Situação Cadastral: Dados não disponíveis</p>
                        )}
                        {historyData?.Result?.[0]?.HistoryBasicData
                          ?.NameHistoryList ? (
                          <p>
                            RJ Histórico:{" "}
                            <strong>
                              {historyData.Result[0].HistoryBasicData.NameHistoryList.some(
                                (item) =>
                                  item.Name.includes("RECUPERACAO JUDICIAL")
                              )
                                ? "Não Ok"
                                : "Ok"}
                            </strong>
                          </p>
                        ) : (
                          <p>Histórico de RJ: Dados não disponíveis</p>
                        )}
                        {dividasData?.Result?.[0]?.GovernmentDebtors ? (
                          <p>
                            Dívida Governo:{" "}
                            <strong>
                              {dividasData.Result[0].GovernmentDebtors
                                .TotalDebtValue > 0
                                ? "Não Ok"
                                : "Ok"}
                            </strong>
                          </p>
                        ) : (
                          <p>Dívida com o Governo: Dados não disponíveis</p>
                        )}
                      </div>
                      <div className="flex flex-col items-center justify-center text-center m-auto ">
                        <img
                          src={getStatusImage(score)}
                          alt="Status"
                          className="ml-[100px] w-40 h-40 mb-0"
                        />
                    
                      </div>
                    </>
                  ) : (
                    <p>
                      Não foram encontrados dados para renderizar. Por favor,
                      verifique a resposta da API.
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p>
              Não foram encontrados dados para renderizar. Por favor, verifique
              a resposta da API.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default ScoreVelocimetro;
