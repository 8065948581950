import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { getProcessosEmpresas } from "../slices/processosPJSlice";

function ProcessosJAPJ({ cnpj, search }) {
  const dispatch = useDispatch();
  const { processosEmpresasPJ } = useSelector((state) => state.processosPJ); // Assumindo que o estado é chamado 'processos'

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getProcessosEmpresas({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };

    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  const formatCNPJ = useCallback((value) => {
    if (!value) return ""; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const numbers = value.replace(/\D/g, "");

    if (numbers.length === 11) {
      // Formatar como CPF
      return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (numbers.length === 14) {
      // Formatar como CNPJ
      return numbers.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }

    return numbers; // Retorna o número sem formatação se não for nem CPF nem CNPJ
  }, []);

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }

    // Verifica se o valor é -1 e retorna uma string vazia nesse caso
    if (valor === -1) {
      return "";
    }

    // Formata o valor como moeda
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayLawsuits = search
    ? processosEmpresasPJ?.Result?.[0]?.Lawsuits?.Lawsuits ?? []
    : [];

  // Garante que displayLawsuits é um array antes de ordenar
  const sortedLawsuits = Array.isArray(displayLawsuits)
    ? [...displayLawsuits].sort(
        (a, b) => new Date(b.LastUpdate) - new Date(a.LastUpdate)
      )
    : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && sortedLawsuits.length > 0 && (
        <div className="table-responsive max-w-full pr-20">
          <table className="min-w-full divide-y divide-gray-400 ">
            <tbody className="bg-white">
              {sortedLawsuits.map((lawsuit, index) => {
                // Verifica se o valor é menor ou igual a 0 e, nesse caso, pula o processo
                if (lawsuit.Value <= 0) {
                  return null; // Não renderiza nada se o valor for <= 0
                }

                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`border-b border-gray-400 ${
                        index > 0 ? "border-t border-gray-200" : ""
                      }`}
                    >
                      <th className="w-2/4 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Número do Processo
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {lawsuit.Number}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tipo
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {lawsuit.Type}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Estado
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {lawsuit.State}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {lawsuit.Status}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Última Atualização
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {new Date(lawsuit.LastUpdate).toLocaleDateString()}
                      </td>
                    </tr>
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Valor
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {formatarCapital(lawsuit.Value)}
                      </td>
                    </tr>
                    {lawsuit.Parties?.filter(
                      (party) =>
                        party.Type === "CLAIMANT" || party.Type === "CLAIMED"
                    ).map((party, partyIndex) => (
                      <React.Fragment key={partyIndex}>
                        <tr className="border-t border-gray-200">
                          <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Documento
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {formatCNPJ(party.Doc)}
                          </td>
                        </tr>
                        <tr>
                          <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nome
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {party.Name}
                          </td>
                        </tr>
                        <tr>
                          <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Polaridade
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {party.Polarity}
                          </td>
                        </tr>
                        <tr>
                          <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Tipo Específico
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {party.PartyDetails.SpecificType}
                          </td>
                        </tr>
                        <tr>
                          <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Tipo
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {party.Type === "CLAIMANT"
                              ? "RECLAMANTE"
                              : "RECLAMADO"}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {!loading && submitted && sortedLawsuits.length === 0 && (
        <p>
          Não foram encontrados dados para renderizar. Por favor, verifique a
          resposta da API.
        </p>
      )}
    </div>
  );
}

export default ProcessosJAPJ;
