import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import PresCobPJ from "./PresCobPJ";
import ScoreQuod from "./ScoreQuod";
import DadosScore from "./DadosScore";
import ScoreVelocimetro from "./ScoreVelocimetro";
import { useDispatch, useSelector } from "react-redux";
import { getPresCobPJ } from "../slices/scorePJSlice";
import NavegacaoPJ from "./NavegacaoPJ";
import CNPJForm from "./CNPJForm";
import { useCNPJ } from "./CNPJContext"; // Importa o contexto
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router-dom"; // Importa useLocation para detectar a rota atual
import NavegacaoPJAgentes from "./NavegacaoPJAgentes";
import { MdCreditScore } from "react-icons/md";
import { BsClipboard2Data } from "react-icons/bs";
import { FaTachometerAlt } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";

function PJScore({ activeTab, setActiveTab, cnpj2 }) {
  const { cnpj, setCnpj } = useCNPJ(); // Usa o contexto para obter e definir o CNPJ
  const [submittedCNPJ, setSubmittedCNPJ] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [initialSearchDone, setInitialSearchDone] = useState(false); // Novo estado
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation(); // Usado para verificar a rota atual
  const dispatch = useDispatch();
  const { presCobPJ } = useSelector((state) => state.scorePJ);

  // Função para formatar o CNPJ enquanto o usuário digita
  const formatCNPJ = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }
    return cleaned;
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    if (typeof value === "string") {
      return value.replace(/\D/g, "");
    }
    return ""; // ou algum valor padrão apropriado
  };

  // Limitar a entrada do usuário para no máximo 14 caracteres
  const handleChange = (event) => {
    let value = event.target.value;
    // Remove todos os caracteres não numéricos e limita a 14 caracteres
    value = value.replace(/\D/g, "").slice(0, 14);
    setCnpj(formatCNPJ(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cnpjToUse = getOnlyNumbers(cnpj2 || cnpj); // Usa o CNPJ correto
    setSubmittedCNPJ(cnpjToUse);
    setSubmit(true);
    setSearch(false);
  };

  const handleSearch = useCallback(
    (event) => {
      if (event) event.preventDefault();
      const cnpjToSearch = getOnlyNumbers(cnpj2 || cnpj); // Prioriza cnpj2
      setSubmittedCNPJ(cnpjToSearch);
      setSearch(true);
      setSubmit(false);
      setInitialSearchDone(true);
    },
    [cnpj, cnpj2]
  );

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Limpa o erro anterior antes de uma nova solicitação

        try {
          if (search) {
            // Despacha a ação Redux para buscar os dados
            await dispatch(getPresCobPJ({ cnpj: submittedCNPJ }));
          } else {
            // Faz a requisição POST sem tratar a resposta
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/consultarcnpj`,
              { cnpj: submittedCNPJ }
            );
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError(
            "Erro ao buscar dados. Por favor, tente novamente mais tarde."
          ); // Define a mensagem de erro
        }

        setLoading(false);
        setSubmitted(true); // Marca a busca como concluída
        setSubmit(false); // Reseta o estado de submit após a busca
      };

      fetchData();
    }
  }, [submit, search, dispatch, submittedCNPJ, setSubmit]);

  useEffect(() => {
    if (
      (getOnlyNumbers(cnpj).length === 14 ||
        getOnlyNumbers(cnpj2).length === 14) &&
      !initialSearchDone
    ) {
      handleSearch();
    }
  }, [cnpj, cnpj2, handleSearch, initialSearchDone]); // Adiciona initialSearchDone na lista de dependências

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? presCobPJ : [];

  // Verifica se a rota atual contém "agentes-seb"
  const currentViewIsAgentes = location.pathname.includes("agentes-seb");

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      <div className="flex flex-col justify-center items-center w-full h-full px-3 md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Jurídica - Score
        </h1>
        <p className="text-gray-300 pb-2">Insira o CNPJ para obter o Score</p>
        <CNPJForm
          cnpj={cnpj2 || cnpj}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="flex justify-end w-full">
          {currentViewIsAgentes ? (
            <NavegacaoPJAgentes
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <NavegacaoPJ />
          )}
        </div>
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4">
          <table className="w-full table-fixed text-left">
            <thead>
              <tr className="bg-gray-100 relative">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <MdCreditScore className="w-10 h-10 text-yellow-500" />
                    <span>Score</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between">
                  <span className="flex items-center gap-2">
                    <FaFileInvoiceDollar className="w-10 h-10 text-indigo-300" />
                    Presença em Cobrança
                  </span>
                  {displayData && (
                    <div className="flex items-center absolute top-0 right-0">
                      <span className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data da Consulta
                      </span>
                      <span className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {new Date(displayData.QueryDate).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <ScoreQuod
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <PresCobPJ
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <BsClipboard2Data className="w-10 h-10 text-pink-500" />
                    <span>Dados</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  <div className="flex items-center gap-2">
                    <FaTachometerAlt className="w-10 h-10 text-green-800" />
                    <span>Situação Geral</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <DadosScore
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <ScoreVelocimetro
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PJScore;
