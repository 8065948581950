import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getScoreQuod } from "../slices/scorePJSlice";

import { IoIosInformationCircleOutline } from "react-icons/io";

function ScoreQuod({ cnpj, search }) {
  const dispatch = useDispatch();
  const { scoreQuodPJ } = useSelector((state) => state.scorePJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(null);
  const [showScorePopup, setShowScorePopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false); // Estado para controlar a visibilidade do pop-up


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getScoreQuod({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };

    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? scoreQuodPJ : [];

  // Descrições para códigos específicos
  const codeDescriptions = {
    301: "Opt-out do Cadastro Positivo",
    302: "Suspensão de acesso ao Score",
    303: "Sem histórico de crédito, ou com histórico, mas não comunicado, ou dentro do prazo legal de comunicação.",
    304: "Não há informações suficientes para o cálculo",
    305: "CNPJ inválido",
    310: "Não há informações suficientes para o cálculo (cenário com Sócios + CNPJ)",
    311: "Empresa Pública",
    312: "Status na Receita inválido",
    313: "Possui sócio PEP ou VIP",
    314: "Empresa Falida",
  };

  // Funções de interpretação ajustadas
  const interpretPaymentTiming = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "A empresa não possui pagamentos realizados em dia.";
    } else if (value > 0 && value < 40) {
      return "RISCO ALTO: A empresa apresenta um volume baixo de compromissos financeiros pagos em dia.";
    } else if (value >= 40 && value < 70) {
      return "RISCO MÉDIO: A empresa apresenta um volume médio de compromissos financeiros pagos em dia.";
    } else if (value >= 70 && value <= 100) {
      return "RISCO BAIXO: A empresa apresenta um volume alto de compromissos financeiros pagos em dia.";
    }
    return "Valor inválido.";
  };

  const interpretDefaultLevel = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "Não possui parcela em atraso no período calculado.";
    } else if (value > 0 && value <= 1) {
      return "RISCO BAIXO: A empresa não apresenta dívidas vencidas e não pagas.";
    } else if (value > 1 && value <= 50) {
      return "RISCO MÉDIO: A empresa apresenta dívidas vencidas e não pagas com um tempo médio de atraso.";
    } else if (value > 50 && value <= 100) {
      return "RISCO ALTO: A empresa apresenta dívidas vencidas e não pagas com um tempo elevado de atraso.";
    }
    return "Valor inválido.";
  };

  const interpretDebtLevel = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "Não possui parcelas em atraso no período calculado.";
    } else if (value > 0 && value <= 1) {
      return "RISCO BAIXO: A empresa não apresenta contratos em aberto.";
    } else if (value > 1 && value <= 50) {
      return "RISCO MÉDIO: A empresa apresenta uma quantidade média de contratos em aberto.";
    } else if (value > 50 && value <= 100) {
      return "RISCO ALTO: A empresa apresenta uma quantidade elevada de contratos em aberto.";
    }
    return "Valor inválido.";
  };

  const interpretEmergencyDebt = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "Não há utilização de crédito emergencial no período calculado.";
    } else if (value > 0 && value < 20) {
      return "RISCO BAIXO: A empresa utilizou um valor baixo de crédito emergencial.";
    } else if (value >= 20 && value < 80) {
      return "RISCO MÉDIO: A empresa utilizou um valor médio de crédito emergencial.";
    } else if (value >= 80 && value <= 100) {
      return "RISCO ALTO: A empresa utilizou um valor elevado de crédito emergencial.";
    }
    return "Valor inválido.";
  };

  const interpretConsumptionProfile = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "Não possui contratos no período calculado.";
    } else if (value > 0 && value <= 30) {
      return "RISCO ALTO: A empresa utilizou produtos de crédito com risco alto.";
    } else if (value > 30 && value < 80) {
      return "RISCO MÉDIO: A empresa utilizou produtos de crédito com risco médio.";
    } else if (value >= 80 && value <= 100) {
      return "RISCO BAIXO: A empresa utilizou produtos de crédito com risco baixo.";
    }
    return "Valor inválido.";
  };

  const interpretCreditSearch = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "RISCO BAIXO: A empresa não apresentou novas contratações de crédito nos últimos 12 meses.";
    } else if (value > 0 && value <= 60) {
      return "RISCO MÉDIO: A empresa contratou uma quantidade média de produtos de crédito nos últimos 12 meses.";
    } else if (value > 60 && value <= 100) {
      return "RISCO ALTO: A empresa contratou uma quantidade elevada de produtos de crédito nos últimos 12 meses.";
    }
    return "Valor inválido.";
  };

  const interpretRelationshipProfile = (value) => {
    if (codeDescriptions[value]) {
      return codeDescriptions[value];
    } else if (value === 0) {
      return "RISCO ALTO: Houve migração total de todos os contratos de crédito para outro(s) credores em algum momento nos últimos 6 meses. Isso significa que não houve estabilidade na carteira de crédito e todos os credores antigos foram substituídos por novos credores.";
    } else if (value > 0 && value <= 90) {
      return "RISCO MÉDIO: Houve pequena/média migração de contratos entre credores nos últimos 6 meses.";
    } else if (value > 90 && value <= 100) {
      return "RISCO BAIXO: Não houve migração de credor entre os contratos existentes no início do período.";
    }
    return "Valor inválido.";
  };

  const descriptions = {
    "Pagamento em dia":
      "Avalia a quantidade de compromissos financeiros que a empresa pagou pontualmente nos últimos 12 meses",
    "Atraso vigente - Nivel de inadimplencia":
      "Avalia o tempo de atraso dos compromissos financeiros em aberto nos últimos 12 meses",
    "Endividamento - Atraso vigente":
      "Avalia a quantidade de contratos vigentes nos últimos 12 meses",
    "Endividamento - Emergencial":
      "Avalia o valor de crédito rotativo utilizado nos últimos 180 dias",
    "Perfil de Consumo - Contratacoes":
      "Avalia o perfil dos produtos de crédito contratados nos últimos 12 meses",
    "Busca por Credito":
      "Avalia a frequência de novos contratos nos últimos 12 meses",
    "Perfil de Relacionamento":
      "Avalia a estabilidade de relacionamento com os credores nos últimos 180 dias",
  };

  const scoreRanges = {
    "300 - 500": "MUITO ALTO",
    "501 - 600": "ALTO",
    "601 - 700": "MÉDIO",
    "701 - 900": "BAIXO",
    "901 - 1000": "MUITO BAIXO",
  };

  const handleIconClick = (name) => {
    if (showPopup === name) {
      setShowPopup(null);
    } else {
      setShowPopup(name);
    }
  };

  const handleScoreIconClick = () => {
    setShowScorePopup(!showScorePopup);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showPopup && !event.target.closest(".popup-content")) {
        setShowPopup(null);
      }
      if (showScorePopup && !event.target.closest(".score-popup-content")) {
        setShowScorePopup(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPopup, showScorePopup]);

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].QUODCreditScoreCompany ? (
        <div className="px-3 relative mt-2">
          <IoIosInformationCircleOutline
            className="absolute left-0 top-0 text-blue-500 cursor-pointer ml-5 score-popup-content"
            onClick={handleScoreIconClick}
          />
          <p className="font-bold uppercase mb-3 ml-8">
            Score: {displayData.Result[0].QUODCreditScoreCompany.Score}
            {showScorePopup && (
              <div className="absolute z-10 p-2 mt-2 bg-white border border-gray-300 shadow-lg rounded ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Faixa de Score
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Faixa de Risco
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(scoreRanges).map(([range, risk], index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {range}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {risk}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </p>
          <p className="font-bold uppercase text-gray-600 mb-2">Razões:</p>
          <ul className="ml-2 mb-3">
            {displayData.Result[0].QUODCreditScoreCompany.Reasons.map(
              (reason, index) => (
                <li
                  key={index}
                  className="text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {reason}
                </li>
              )
            )}
          </ul>
          <p className="font-bold uppercase text-gray-600 mb-2">Indicadores:</p>
          <ul className="ml-2 leading-tight">
            {displayData.Result[0].QUODCreditScoreCompany.Indicators.map(
              (indicator, index) => {
                const indicatorValue = indicator.Value
                  ? Number(indicator.Value)
                  : null;

                // Verifique se o Name está presente
                if (indicator.Name) {
                  return (
                    <li key={index} className="relative mb-2">
                      <div className="relative inline-flex items-center">
                        <p className="font-semibold text-sm text-gray-800 uppercase">
                          {indicator.Name}
                        </p>
                        <IoIosInformationCircleOutline
                          className="text-blue-500 cursor-pointer popup-content ml-2"
                          onClick={() => handleIconClick(indicator.Name)}
                        />
                      </div>
                      {showPopup === indicator.Name && (
                        <div className="mt-2 p-2 bg-white border border-gray-300 shadow-lg rounded">
                          <p className="text-base text-gray-700">
                            {descriptions[indicator.Name]}
                          </p>
                        </div>
                      )}
                      <div className="mt-1">
                        <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {indicator.Name === "Pagamento em dia"
                            ? interpretPaymentTiming(indicatorValue)
                            : indicator.Name ===
                              "Atraso vigente - Nivel de inadimplencia"
                            ? interpretDefaultLevel(indicatorValue)
                            : indicator.Name ===
                              "Endividamento - Atraso vigente"
                            ? interpretDebtLevel(indicatorValue)
                            : indicator.Name === "Endividamento - Emergencial"
                            ? interpretEmergencyDebt(indicatorValue)
                            : indicator.Name ===
                              "Perfil de Consumo - Contratacoes"
                            ? interpretConsumptionProfile(indicatorValue)
                            : indicator.Name === "Busca por Credito"
                            ? interpretCreditSearch(indicatorValue)
                            : indicator.Name === "Perfil de Relacionamento"
                            ? interpretRelationshipProfile(indicatorValue)
                            : indicatorValue}
                        </span>
                      </div>
                    </li>
                  );
                } else {
                  // Se o Name não estiver presente, mostre apenas o valor traduzido
                  const translatedValue = codeDescriptions[indicatorValue];

                  return (
                    <li key={index} className="relative mb-2">
                      <div className="mt-1">
                        <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {translatedValue || "Descrição não disponível."}
                        </span>
                      </div>
                    </li>
                  );
                }
              }
            )}
          </ul>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default ScoreQuod;
