import React from "react";

const NavegacaoPJAgentes = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { id: "score", label: "Score" },
    { id: "dados-essenciais", label: "Dados Essenciais" },
    { id: "processos", label: "Processos" },
    { id: "protestos", label: "Protestos" },
  ];

  return (
    <div className="flex mx-3 md:mx-4 mt-5 mb-5">
      {tabs.map((tab) => (
        tab.id !== activeTab && (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            {tab.label}
          </button>
        )
      ))}
    </div>
  );
};

export default NavegacaoPJAgentes;
