import React, { useEffect, useState } from "react";
import IAPJ from "./IAPJ";
import { getIAPJ } from "../slices/iaPJSlice";
import { useDispatch, useSelector } from "react-redux";
import CNPJForm from "./CNPJForm";
import { FcAndroidOs } from "react-icons/fc";

function PJIA() {
  const [cnpj, setCnpj] = useState("");
  const [submittedCNPJ, setSubmittedCNPJ] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);

  // Função para formatar o CNPJ enquanto o usuário digita
  const formatCNPJ = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }
    return cleaned;
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  // Limitar a entrada do usuário para no máximo 14 caracteres
  const handleChange = (event) => {
    let value = event.target.value;
    // Remove todos os caracteres não numéricos e limita a 14 caracteres
    value = value.replace(/\D/g, "").slice(0, 14);
    setCnpj(formatCNPJ(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cnpjNumbers = getOnlyNumbers(cnpj);
    setSubmittedCNPJ(cnpjNumbers); // Armazena o CNPJ quando o botão Nova Consulta é clicado
    setSubmit(true);
    setSearch(false); // Reseta o estado de search
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSubmittedCNPJ(getOnlyNumbers(cnpj)); // Armazena o CNPJ quando o botão Histórico é clicado
    setSearch(true);
    setSubmit(false); // Reseta o estado de submit
  };

  const dispatch = useDispatch();
  const { iaPJ } = useSelector((state) => state.iaPJ);

  useEffect(() => {
    if (search) {
      const fetchData = async () => {
        try {
          if (search) {
            await dispatch(getIAPJ({ cnpj: submittedCNPJ }));
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
        setSubmit(false);
      };

      fetchData();
    }
  }, [submit, search, submittedCNPJ, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? iaPJ : [];

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-full h-full md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Jurídica - IA Generativa
        </h1>
        <p className="text-gray-300 pb-2">Insira o CNPJ para obter o Resumo</p>
        <CNPJForm
          cnpj={cnpj}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4 pt-5">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between">
                  <div className="flex items-center gap-2">
                    <FcAndroidOs className="w-10 h-10" />
                    <span>IA Pessoa Jurídica</span>
                  </div>
                  {displayData &&
                    displayData.Result &&
                    displayData.Result[0] &&
                    displayData.Result[0].GenerativeAIData && (
                      <div className="flex items-center">
                        <span className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Data da Consulta
                        </span>
                        <span className="px-3 py-1 font-semibold text-sm text-gray-800">
                          {new Date(displayData.QueryDate).toLocaleDateString(
                            "pt-BR"
                          )}
                        </span>
                      </div>
                    )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 pr-1 align-top">
                  <IAPJ
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PJIA;
