import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavegacaoPending = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Determina qual visão está ativa com base no caminho atual
  let currentView = "";
  if (currentPath.includes("dashboard")) {
    currentView = "dashboard";
  } else if (currentPath.includes("pending")) {
    currentView = "pending";
  }

  return (
    <div className="flex mb-4 items-end justify-end">
      {currentView === "dashboard" && (
        <>
          <Link
            to="/pending"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Solicitações
          </Link>
        </>
      )}
      {currentView === "pending" && (
        <>
          <Link
            to="/dashboard"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dashboard
          </Link>
        </>
      )}
    </div>
  );
};

export default NavegacaoPending;
