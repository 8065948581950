import React from "react";

const Header = ({ signOut, user }) => {
  return (
    <div className="flex h-20 bg-gradient-to-r from-blue-600 via-gray-700 to-blue-800 justify-between items-center ">
      <h1 className="text-white text-2xl font-bold mx-auto pl-20">Credit RX</h1>
      <ul className="flex justify-end">
        <li>
          <span className="text-white mr-4 cursor-pointer" onClick={signOut}>
            {user.signInDetails.loginId}
          </span>
          <span className="text-white mr-4 cursor-pointer" onClick={signOut}>
            Log Out
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Header;
