import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

import { CNPJProvider } from "./components/CNPJContext";
import { CPFProvider } from "./components/CPFContext"; // Importando o CPFProvider

Amplify.configure(awsmobile);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CNPJProvider>
        <CPFProvider>
          <App />
        </CPFProvider>
      </CNPJProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
