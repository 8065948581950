import React, { useState, useEffect } from "react";
import { FcFilledFilter } from "react-icons/fc";

const FiltroHistorico = ({ setAnoMes }) => {
  // Obtém o ano e o mês atual
  const anoAtual = new Date().getFullYear().toString();
  const mesAtual = (new Date().getMonth() + 1).toString().padStart(2, "0");

  // Define os estados iniciais com o ano e mês atuais
  const [ano, setAno] = useState(anoAtual);
  const [mes, setMes] = useState(mesAtual);

  // Gera os anos disponíveis (ajuste conforme necessário)
  const anos = Array.from({ length: 20 }, (_, i) =>
    (new Date().getFullYear() - 10 + i).toString()
  );

  // Meses em português
  const meses = [
    { value: "01", label: "Janeiro" },
    { value: "02", label: "Fevereiro" },
    { value: "03", label: "Março" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Maio" },
    { value: "06", label: "Junho" },
    { value: "07", label: "Julho" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  // Atualiza anoMes quando ano ou mes mudam
  useEffect(() => {
    setAnoMes(`${ano}-${mes}`);
  }, [ano, mes, setAnoMes]);

  return (
    <div className="flex flex-col space-y-2 p-2 bg-white rounded-lg shadow-md mb-4">
      <div className="flex items-center">
        <FcFilledFilter className="h-10 w-10" />
        <h2 className="text-xl font-bold tracking-wider underline">Filtros</h2>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex flex-col space-y-2 flex-1">
          <label htmlFor="ano" className="font-semibold text-gray-700">
            Ano:
          </label>
          <select
            id="ano"
            value={ano}
            onChange={(e) => setAno(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Selecione o ano</option>
            {anos.map((a) => (
              <option key={a} value={a}>
                {a}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col space-y-2 flex-1">
          <label htmlFor="mes" className="font-semibold text-gray-700">
            Mês:
          </label>
          <select
            id="mes"
            value={mes}
            onChange={(e) => setMes(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Selecione o mês</option>
            {meses.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FiltroHistorico;
