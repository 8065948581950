import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScoreQuodDashboard } from "../../slices/scorePJSlice";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FiltroHistorico from "../../components/Historico/FiltroHistorico";
import NavegacaoPending from "../../components/NavegacaoPending";
import { getClienteScore } from "../../slices/dashboardSlice";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import {
  MdAttachMoney,
  MdCreditScore,
  MdOutlineLibraryBooks,
  MdOutlinePending,
} from "react-icons/md";
import {
  FaBalanceScale,
  FaCalendarAlt,
  FaHourglass,
  FaRegThumbsUp,
  FaRegThumbsDown,
} from "react-icons/fa";
import { GiArchiveResearch } from "react-icons/gi";
import { HiOutlineIdentification } from "react-icons/hi";
import { RiGovernmentLine } from "react-icons/ri";

// Registrar componentes do Chart.js
ChartJS.register(ArcElement, Tooltip);

const Historico = () => {
  const dispatch = useDispatch();
  const { clienteScores } = useSelector((state) => state.dashboard);

  const anoAtual = new Date().getFullYear().toString();
  const mesAtual = (new Date().getMonth() + 1).toString().padStart(2, "0");

  const [anoMes, setAnoMes] = useState(`${anoAtual}-${mesAtual}`);

  useEffect(() => {
    dispatch(getScoreQuodDashboard({ anoMes }));
    dispatch(getClienteScore({ ano_mes: anoMes }));
  }, [dispatch, anoMes]);

  // Contadores para Approved, Rejected e Pending
  let approved = 0;
  let rejected = 0;
  let pending = 0;

  if (Array.isArray(clienteScores) && clienteScores.length > 0) {
    clienteScores.forEach((item) => {
      const opinion = item.opinion1;
      if (opinion === "Approved") {
        approved++;
      } else if (opinion === "Rejected") {
        rejected++;
      } else if (opinion === "Pending") {
        pending++;
      }
    });
  }

  // Configuração dos dados para o gráfico Doughnut
  const data = {
    labels: ["Approved", "Rejected", "Pending"],
    datasets: [
      {
        data: [approved, rejected, pending],
        backgroundColor: [
          "#00fa9a", // Verde para Approved
          "#ff7f7f", // Vermelho para Rejected
          "#ffff00", // Amarelo para Pending
        ],
        hoverBackgroundColor: [
          "#228B22", // Verde mais escuro para hover em Approved
          "#ff0000", // Vermelho mais escuro para hover em Rejected
          "#e0a800", // Amarelo mais escuro para hover em Pending
        ],
      },
    ],
  };

  const totalConsultas = 400;
  const consultas = clienteScores.length;
  const porcentagem = (consultas / totalConsultas) * 100 || 0;

  const rejectedPercentage = (rejected / totalConsultas) * 100 || 0;
  const pendingPercentage = (pending / totalConsultas) * 100 || 0;
  const approvedPercentage = (approved / totalConsultas) * 100 || 0;

  return (
    <div className="flex flex-col p-4 pt-0">
      <NavegacaoPending />
      <h2 className="text-3xl font-bold mb-4 text-white">Dashboard</h2>
      <FiltroHistorico setAnoMes={setAnoMes} />
      <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
        <h2 className="text-xl font-bold mb-2">Consultas</h2>
        <div className="flex items-center justify-center m-auto gap-10">
          <div className="flex flex-col items-center justify-center mb-4 w-60">
            <h2 className="text-xl font-bold mb-2 whitespace-nowrap">
              Porcentagem de Consultas
            </h2>
            <div style={{ position: "relative" }}>
              <CircularProgressbar
                value={porcentagem}
                maxValue={100}
                styles={buildStyles({
                  pathColor: "#28a745",
                  trailColor: "#e0f2e9",
                  strokeLinecap: "round",
                  rotation: 0.75,
                  textSize: "16px",
                  pathTransitionDuration: 0.5,
                  pathTransition: "none",
                })}
                text={`${porcentagem.toFixed(2)}%`}
              />
              {/* Borda ao redor do CircularProgressbar */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "2px solid blue",
                  borderRadius: "50%",
                  pointerEvents: "none", // Para não interferir na interação do gráfico
                }}
              />
            </div>
            <div className="text-center">
              <span className="text-lg font-semibold">
                {consultas} / {totalConsultas} Consultas
              </span>
              <p>{porcentagem.toFixed(2)}%</p>
            </div>
          </div>
          <div className="flex flex-col items-center w-60">
            <h2 className="text-xl font-bold mb-2">Distribuição</h2>
            <Doughnut
              data={data}
              options={{ plugins: { legend: { display: false } } }}
            />
            <div className="flex gap-2 mt-4 text-center">
              <p>
                Aprovadas <br />
                {approved}
                <br />
              </p>
              <p>
                Rejeitadas <br />
                {rejected}
              </p>
              <p>
                Pendentes <br />
                {pending}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
          <h2 className="font-bold p-1">Progresso das Consultas</h2>
          <div className="w-full bg-gray-200 rounded-full h-6 mb-4 flex">
            <div
              className="bg-[#ff7f7f] h-6 rounded-l-full"
              style={{ width: `${rejectedPercentage}%` }}
            ></div>
            <div
              className="bg-[#ffff00] h-6"
              style={{ width: `${pendingPercentage}%` }}
            ></div>
            <div
              className="bg-[#00fa9a] h-6 rounded-r-full"
              style={{ width: `${approvedPercentage}%` }}
            ></div>
          </div>
          <p>{`Rejeitadas: ${rejected}`}</p>
          <p>{`Pendentes: ${pending}`}</p>
          <p>{`Aprovadas: ${approved}`}</p>
        </div>
      </div>
      <h2 className="text-3xl text-white">Últimas Consultas</h2>
      <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
        <div>
          <p>COMER_1</p>
          <p>43.817.715/0001-44</p>
          <hr />
        </div>
        <hr />
        <div>
          <h2 className="font-bold p-1">Última Análise</h2>
          <div className="mb-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaCalendarAlt className="h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Consulta:</strong> 17/09/2024 15:35
              </span>
            </div>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdOutlineLibraryBooks className="text-indigo-700 h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Processos &lt;= 50K:</strong>
                <FaRegThumbsDown className="text-red-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaBalanceScale className="text-[#8B4513] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Protestos &lt;= 50K:</strong>
                <FaRegThumbsDown className="text-red-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <HiOutlineIdentification className="text-[#00FFFF] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Situação Cadastral:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <GiArchiveResearch className="text-[#A020F0] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>RJ Histórico:</strong>
                <FaRegThumbsDown className="text-red-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <RiGovernmentLine className="text-[#ff0000] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Dívida Governo:</strong>
                <FaRegThumbsDown className="text-red-500 w-5 h-5" />
              </span>
            </div>
          </div>
          <div className="my-2 gap-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdAttachMoney className="text-yellow-500 h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Limites:</strong> R$ 100.000,00
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaHourglass className="text-[#0000ff] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Expira:</strong> 17/09/2025 15:35
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdCreditScore className="text-[#FFD700] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Score:</strong> D
              </span>
            </div>
          </div>
        </div>
        <div className="mt-2 flex">
          <div className="flex items-center bg-gray-200 rounded-full max-w-max">
            <div className="bg-transparent p-1 rounded-full flex-shrink-0">
              <MdOutlinePending className="h-7 w-7 text-yellow-400" />
            </div>
            <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
              <strong>Parecer:</strong> PENDENTE
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
        <div>
          <p>COMER_GEN_1</p>
          <p>41.186.676/0001-07</p>
          <hr />
        </div>
        <hr />
        <div>
          <h2 className="font-bold p-1">Última Análise</h2>
          <div className="mb-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaCalendarAlt className="h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Consulta:</strong> 20/09/2024 13:02
              </span>
            </div>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdOutlineLibraryBooks className="text-indigo-700 h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Processos &lt;= 300K:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaBalanceScale className="text-[#8B4513] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Protestos &lt;= 300K:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <HiOutlineIdentification className="text-[#00FFFF] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Situação Cadastral:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <GiArchiveResearch className="text-[#A020F0] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>RJ Histórico:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <RiGovernmentLine className="text-[#ff0000] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Dívida Governo:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
          </div>
          <div className="my-2 gap-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdAttachMoney className="text-yellow-500 h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Limites:</strong> R$ 500.000,00
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaHourglass className="text-[#0000ff] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Expira:</strong> 20/09/2025 13:02
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdCreditScore className="text-[#FFD700] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Score:</strong> B
              </span>
            </div>
          </div>
        </div>
        <div className="mt-2 flex">
          <div className="flex items-center bg-gray-200 rounded-full max-w-max">
            <div className="bg-transparent p-1 rounded-full flex-shrink-0">
              <FaRegThumbsUp className="h-7 w-7 text-green-400" />
            </div>
            <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
              <strong>Parecer:</strong> APROVADO
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
        <div>
          <p>CONSUMER_1</p>
          <p>95.724.739/0001-52</p>
          <hr />
        </div>
        <hr />
        <div>
          <h2 className="font-bold p-1">Última Análise</h2>
          <div className="mb-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaCalendarAlt className="text-[#000] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Consulta:</strong> 25/09/2024 14:37
              </span>
            </div>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdOutlineLibraryBooks className="text-indigo-700 h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Processos &lt;= 300K:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaBalanceScale className="text-[#8B4513] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Total Protestos &lt;= 300K:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <HiOutlineIdentification className="text-[#00FFFF] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Situação Cadastral:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <GiArchiveResearch className="text-[#A020F0] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>RJ Histórico:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <RiGovernmentLine className="text-[#ff0000] h-7 w-7" />
              </div>
              <span className="flex items-center gap-1 ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Dívida Governo:</strong>
                <FaRegThumbsUp className="text-green-500 w-5 h-5" />
              </span>
            </div>
          </div>
          <div className="my-2 gap-2 flex">
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdAttachMoney className="text-yellow-500 h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Limite:</strong> R$ 5.000.000,00
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <FaHourglass className="text-[#0000ff] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Expira:</strong> 25/09/2025 14:37
              </span>
            </div>
            <div className="flex items-center bg-gray-200 rounded-full max-w-max">
              <div className="bg-transparent p-1 rounded-full flex-shrink-0">
                <MdCreditScore className="text-[#FFD700] h-7 w-7" />
              </div>
              <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
                <strong>Score:</strong> C
              </span>
            </div>
          </div>
        </div>
        <div className="mt-2 flex">
          <div className="flex items-center bg-gray-200 rounded-full max-w-max">
            <div className="bg-transparent p-1 rounded-full flex-shrink-0">
              <MdOutlinePending className="h-7 w-7 text-yellow-400" />
            </div>
            <span className="ml-1 text-sm text-gray-800 px-2 py-0.5 rounded-md">
              <strong>Parecer:</strong> PENDENTE
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historico;
